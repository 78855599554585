import { Link } from "gatsby";
import React from "react";
import { BackButton } from "../components/Parts/backButton";
import { Logo } from "../components/Parts/logo";
import { StepCount } from "../components/Parts/stepCount";
import { Title } from "../components/Parts/title";
import * as styles from "../styles/legals.module.scss";

const Legals = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={`${styles.flexColumn} ${styles.margin}`}>
        <div className={styles.flexRow}>
          <BackButton />
          <Title content="Mentions légales" alignLeft={true} />
        </div>
      </div>
    </div>
  );
};

export default Legals;
